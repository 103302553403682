// 浏览器缓存
import {commonDownloadAPI} from "@/api/Home/Common/commonAPI";

export const SET_STORAGE = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value))
}
export const GET_STORAGE = key => {
    return JSON.parse(window.localStorage.getItem(key))
}
export const REMOVE_STORAGE = key => {
    window.localStorage.removeItem(key)
}
export const CLEAR_STORAGE = () => {
    window.localStorage.clear()
}

// 在数组中取出id
export const GET_ID = (dataList) => {
    if (dataList.length > 0) {
        let ids = []
        ids = dataList.map(item => {
            return item.id || item.userId || item.goodsId || item.domainId
        })
        return ids
    }
    return []
}

// 取出嵌套的id
export const RECURSION_BY_IDS = (data, idsList) => {
    if (data) {
        // 二话不说先push
        idsList.push(data.id)
        // 判断有无孩子，没返回，再调用
        if (data.children && data.children.length > 0) {
            // 循环子节点
            data.children.forEach(item => {
                RECURSION_BY_IDS(item, idsList)
            })
        }
        return idsList
    }
    return []
}


import moment from 'moment'
// 日期时间格式化
export const FORMAT_DATETIME = (date, split = '-') => {
    if (split === '-') {
        return moment(date).format('YYYY-MM-DD HH:mm:ss')
    }
    if (split === '/') {
        return moment(date).format('YYYY/MM/DD HH:mm:ss')
    }
}

// 日期格式化
export const FORMAT_DATE = (date, split = '-') => {
    if (split === '-') {
        return moment(date).format('YYYY-MM-DD')
    }
    if (split === '/') {
        return moment(date).format('YYYY/MM/DD')
    }
}

export const DETEMINE_ONE_DAY = (date) => {
    // 传过来的时间
    const dateTimeString = FORMAT_DATETIME(date);
    // 转换为date
    const dateTime = new Date(dateTimeString);
    // 获取系统当前时间
    const currentDateTime = new Date();
    // 计算两个时间的毫秒差
    const diffMilliseconds = currentDateTime - dateTime
    // 一天的毫秒
    const oneDayMilliseconds = 24 * 60 * 60 * 1000

    // 如果大于，证明满一天
    if (diffMilliseconds >= oneDayMilliseconds) {
        return true;
    } else {
        // 小于证明还没满一天
        return false;
    }
}


// 下载流文件
export const DOWNLOAD_BLOB_FILE = async (file) => {
    const res = await commonDownloadAPI({
        fileName: file.name
    })
    if (res) {
        const blob = new Blob([res], {type: res.type});
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = file.name;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement); //移除元素；防止连续点击创建多个a标签
        window.URL.revokeObjectURL(href);
        return true
    }
    return false
}

// 通用规则
/**
 * 确认密码
 * @param password 新密码
 * @returns {(function(*, *, *): void)|*}
 */
export const CHECK_PASSWORD = (password) => {
    return (rule, value, callback) => {
        if (value !== password) {
            callback(new Error("确认密码和新密码不一致~"))
        }
        callback()
    }
}

/**
 * 截取字符串
 * @param str 字符串
 * @param maxLength 截取的长度
 * @param delimiter 分隔符
 * @returns {string} 返回截取后的字符串
 */
export const CUSTOM_SUBSTRING = (str, maxLength, delimiter = '') => {
    if (!str || str.length === 0) {
        return '';
    }

    if (maxLength <= 0) {
        return '';
    }

    if (!delimiter) {
        return str.substring(0, maxLength);
    }

    let index = str.indexOf(delimiter);
    if (index === -1 || index > maxLength) {
        index = maxLength;
    }
    return str.substring(0, index);
}





