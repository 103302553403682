export const routesList = [
    {
        path: '*',
        redirect: '/home',
        hidden: true
    },
    // 登录
    {
        path: '/defaultLogin',
        name: 'defaultLogin',
        hidden: true,
        component: () => import('@/views/System/LoginRegister.vue'),
    },
    // 403
    {
        path: '/403',
        name: '403',
        hidden: true,
        meta: {title: "无权限,请联系管理员"},
        component: () => import('@views/Error/403.vue'),
    },
    // 后台管理页面
    {
        path: '/home',
        name: 'home',
        redirect: '/defaultMain',
        component: () => import('@views/Home/Home.vue'),
        children: [
            // 首页
            {
                path: '/defaultMain',
                name: 'defaultMain',
                isShow:true,
                noShow:false,

                meta: {title: '首页', icon: 'el-icon-office-building'},
                component: () => import('@views/Home/Main/Main.vue'),
            },
            {
                path: '/baseInfo',
                name: 'baseInfo',
                isShow:true,
                noShow:false,

                meta: {title: '基本信息', icon: 'el-icon-office-building'},
                component: () => import('@views/Home/BaseInfo/BaseInfo.vue'),
            },
            {
                path: '/orderManagement',
                name: 'orderManagement',
                isShow:true,
                noShow:true,
                meta: {title: '订单管理', icon: 'el-icon-office-building'},
                component: () => import('@views/Home/OrderManagement/OrderManagement.vue'),
                children: [
                    {
                        path: '/orderManagementList',
                        name: 'orderManagementList',
                        isShow:true,
                        noShow:true,
                        meta: {title: '订单管理列表', icon: 'el-icon-office-building'},
                        component: () => import('@views/Home/OrderManagement/OrderManagementList/OrderManagementList.vue'),
                    },
                    {
                        path: '/orderExportList',
                        name: 'orderExportList',
                        isShow:true,
                        noShow:true ,

                        meta: {title: '订单导出列表', icon: 'el-icon-office-building'},
                        component: () => import('@views/Home/OrderManagement/OrderExportList/OrderExportList.vue'),
                    }
                ]
            },
            {
                path: '/deliveryManagement',
                name: 'deliveryManagement',
                isShow:true,
                noShow:false,

                meta: {title: '投放管理', icon: 'el-icon-office-building'},
                component: () => import('@views/Home/DeliveryManagement/DeliveryManagement.vue'),
                children: [
                    {
                        path: '/deliveryManagementList',
                        name: 'deliveryManagementList',
                        isShow:true,
                        noShow:false,

                        meta: {title: '投放管理列表', icon: 'el-icon-office-building'},
                        component: () => import('@views/Home/DeliveryManagement/DeliveryManagementList/DeliveryManagementList.vue'),
                    },
                    {
                        path: '/deliveryRecord',
                        name: 'deliveryRecord',
                        isShow:true,
                        noShow:false,

                        meta: {title: '投放回传记录', icon: 'el-icon-office-building'},
                        component: () => import('@views/Home/DeliveryManagement/DeliveryRecord/DeliveryRecord.vue'),
                    }
                ]
            },
            {
                path: '/advertisingChannels',
                name: 'advertisingChannels',
                isShow:true,
                noShow:false,

                meta: {title: '广告渠道', icon: 'el-icon-office-building'},
                component: () => import('@views/Home/AdvertisingChannels/AdvertisingChannels.vue'),
                children: [
                    {
                        path: '/advertisingManagement',
                        name: 'advertisingManagement',
                        isShow:true,
                        noShow:false,

                        meta: {title: '渠道管理', icon: 'el-icon-office-building'},
                        component: () => import('@views/Home/AdvertisingChannels/AdvertisingManagement/AdvertisingManagement.vue'),
                    }
                ]
            },
            {
                path: '/template',
                name: 'template',
                isShow:true,
                noShow:false,

                meta: {title: '模板管理', icon: 'el-icon-office-building'},
                component: () => import('@views/Home/TemplateManagement/TemplateManagement.vue'),
                children: [
                    {
                        path: '/domainTemplate',
                        name: 'domainTemplate',
                        isShow:true,
                        noShow:false,

                        meta: {title: '域名列表', icon: 'el-icon-office-building'},
                        component: () => import('@views/Home/TemplateManagement/DomainName/DomainName.vue'),
                    },
                    {
                        path: '/templateNormal',
                        name: 'templateNormal',
                        isShow:true,
                        noShow:false,

                        meta: {title: '正常模板列表', icon: 'el-icon-office-building'},
                        component: () => import('@views/Home/TemplateManagement/NormalTemplate/NorMalTemplate.vue'),
                    },
                    {
                        path: '/templateShield',
                        name: 'templateShield',
                        isShow:true,
                        noShow:false,

                        meta: {title: '屏蔽模板列表', icon: 'el-icon-office-building'},
                        component: () => import('@views/Home/TemplateManagement/ShieldTemplate/ShieldTemplate.vue'),
                    },
                    {
                        path: '/domainTemplateList',
                        name: 'domainTemplateList',
                        isShow:true,
                        noShow:false,

                        meta: {title: '域名模板列表', icon: 'el-icon-office-building'},
                        component: () => import('@views/Home/TemplateManagement/DomainTemplate/DomainTemplate.vue'),
                    },
                    {
                        path: '/serverList',
                        name: 'serverList',
                        isShow:false,
                        noShow:false,

                        // isShow:(GET_STORAGE('accountInfo') ? 1 : GET_STORAGE('accountInfo').roleId) === 0,
                        meta: {title: '服务器列表', icon: 'el-icon-office-building'},
                        component: () => import('@views/Home/TemplateManagement/ServerList/ServerList.vue'),
                    }
                ]
            },
            {
                path: '/goodsManagement',
                name: 'goodsManagement',
                isShow:true,
                noShow:false,

                meta: {title: '商品管理', icon: 'el-icon-office-building'},
                component: () => import('@views/Home/GoodsManagement/GoodsManagement.vue'),
                children: [
                    {
                        path: '/goodsList',
                        name: 'goodsList',
                        isShow:true,
                        noShow:false,

                        meta: {title: '商品列表', icon: 'el-icon-office-building'},
                        component: () => import('@views/Home/GoodsManagement/GoodsList/GoodsList.vue'),
                    }
                ]
            },
            {
                path: '/permissionsManagement',
                name: 'permissionsManagement',
                isShow:false,
                noShow:false,

                meta: {title: '权限管理', icon: 'el-icon-office-building'},
                component: () => import('@views/Home/PermissionsManagement/PermissionsManagement.vue'),
                children: [
                    {
                        path: '/userList',
                        name: 'userList',
                        meta: {title: '用户列表', icon: 'el-icon-office-building'},
                        isShow:false,
                        noShow:false,
                        component: () => import('@views/Home/PermissionsManagement/UserList/UserList.vue'),
                    }
                ]
            }
        ]
    },
    // 前台页面
    {
        path: "/frontDest",
        name: "frontDest",
        hidden: true,
        front: true,
        redirect: "/frontHome",
        component: () => import('@views/FrontDesk/FrontDesk.vue'),
        children: [
            {
                path: "/frontHome",
                name: "frontHome",
                meta: {title: '首页', icon: 'el-icon-s-home'},
                component: () => import('@views/FrontDesk/FrontHome/FrontHome.vue'),
            },
        ]
    },
    //入口模板
    {
        path: "/bdfff",
        name: "bdfff",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/templateEntrance",
        name: "templateEntrance",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/templateEntrancePlus",
        name: "templateEntrancePlus",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/sm02",
        name: "sm02",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/yf62802",
        name: "yf62802",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/qh01",
        name: "qh01",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/shccc1",
        name: "shccc1",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/ymcc01",
        name: "ymcc01",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/qhhda2",
        name: "qhhda2",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/sh02",
        name: "sh02",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/sjhy07",
        name: "sjhy07",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/sjhy06",
        name: "sjhy06",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/hfly7",
        name: "hfly7",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/jy01",
        name: "jy01",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/sqt01",
        name: "sqt01",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/hongkongtest",
        name: "hongkongtest",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/blbc1",
        name: "blbc1",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/cjbc1",
        name: "cjbc1",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/scyy7",
        name: "scyy7",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/yjy14",
        name: "yjy14",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/yjy13",
        name: "yjy13",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/yf70501",
        name: "yf70501",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/yf70502",
        name: "yf70502",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/jxhm01",
        name: "jxhm01",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    //香港
    {
        path: "/yf71102",
        name: "yf71102",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/yf71101",
        name: "yf71101",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/wc02",
        name: "wc02",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/YT01",
        name: "YT01",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/wc01",
        name: "wc01",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/xzs24",
        name: "xzs24",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/xzs23",
        name: "xzs23",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/yf70403",
        name: "yf70403",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/yf70402",
        name: "yf70402",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/yf70401",
        name: "yf70401",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/sb02",
        name: "sb02",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/sb01",
        name: "sb01",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/sl01",
        name: "sl01",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    {
        path: "/sle7",
        name: "sle7",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/TemplateEntrance.vue'),
    },
    //正常模版
    {
        path: "/default",
        name: "default",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@views/FrontDesk/Template/NormalTemplate/fiveForms.vue'),
    },
    {
        path: "/fourForms",
        name: "fourForms",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/NormalTemplate/fourForms.vue'),
    },
    {
        path: "/default1",
        name: "default1",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/NormalTemplate2v/fiveForms.vue'),
    },
    {
        path: "/normalTemplate3v",
        name: "normalTemplate3v",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/NormalTemplate3v/fiveForms.vue'),
    },
    {
        path: "/threeForms",
        name: "threeForms",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/NormalTemplate3v/threeForms.vue'),
    },
    {
        path: "/oldThreeForms",
        name: "oldThreeForms",
        hidden: true,
        front: true,
        meta: {title: 'CCTV央视记者探访叛逆孩子考上清华不为人知的背后'},
        component: () => import('@/views/FrontDesk/Template/NormalTemplate/threeForms.vue'),
    },
    //屏蔽模版
    {
        path: "/officialWebsite",
        name: "officialWebsite",
        hidden: true,
        front: true,
        meta: {title: '家庭教育'},
        component: () => import('@/views/FrontDesk/Template/ShieldTemplate/officialWebsite/OfficialWebsite.vue'),
    },
    {
        path: "/oppoWebsite",
        name: "oppoWebsite",
        hidden: true,
        front: true,
        meta: {title: '家庭教育'},
        component: () => import('@/views/FrontDesk/Template/ShieldTemplate/oppoWebsite/oppoWebsite.vue'),
    },
    {
        path: "/noForm",
        name: "noForm",
        hidden: true,
        front: true,
        meta: {title: '家庭教育'},
        component: () => import('@/views/FrontDesk/Template/ShieldTemplate/noForm/noForm.vue'),
    },
    {
        path: "/noForm1",
        name: "noForm1",
        hidden: true,
        front: true,
        meta: {title: '家庭教育'},
        component: () => import('@/views/FrontDesk/Template/ShieldTemplate/noForm/noForm1.vue'),
    },
    {
        path: "/haveForm",
        name: "haveForm",
        hidden: true,
        front: true,
        meta: {title: '家庭教育'},
        component: () => import('@/views/FrontDesk/Template/ShieldTemplate/haveForm/haveForm.vue'),
    },
    {
        path: "/haveForm1",
        name: "haveForm1",
        hidden: true,
        front: true,
        meta: {title: '家庭教育'},
        component: () => import('@/views/FrontDesk/Template/ShieldTemplate/haveForm/haveForm1.vue'),
    },
]


