
import Vue from 'vue'
import App from './App.vue'
import router from '@router'
import store from '@store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/index.scss'
import '@styles/form.scss'
import '@styles/table.scss'
import CONSTANT from '@/utils/constant'
import {setupRouterGuard} from "@/router/guard";
import FastClick from 'fastclick'
// 解决手指轻触输入框不聚焦的问题
try {
  let versionNumber = navigator.userAgent.toLowerCase().match(/cpu iphone os (.*?) like mac os/)[1].replace(/_/g, '.')
  if (!(/iphone|ipod|ipad/i.test(navigator.appVersion)) || Number(versionNumber) < 11) {
    // 解决手指轻触输入框不聚焦的问题
    var deviceIsWindowsPhone = navigator.userAgent.indexOf('Windows Phone') >= 0
    var deviceIsIOS = /iP(ad|hone|od)/.test(navigator.userAgent) && !deviceIsWindowsPhone
    FastClick.prototype.focus = function (targetElement) {
      var length
      // Issue #160: on iOS 7, some input elements (e.g. date datetime month) throw a vague TypeError on setSelectionRange. These elements don't have an integer value for the selectionStart and selectionEnd properties, but unfortunately that can't be used for detection because accessing the properties also throws a TypeError. Just check the type instead. Filed as Apple bug #15122724.
      if (deviceIsIOS && targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month') {
        length = targetElement.value.length
        targetElement.setSelectionRange(length, length)
        targetElement.focus()
      } else {
        targetElement.focus()
      }
    }
    FastClick.attach(document.body)
  }
} catch (e) {
    //TODO
}


Vue.config.productionTip = false


// 禁用生产环境提示
Vue.config.productionTip = false

// 全局注册ElementUi-Message组件
Vue.$message = ElementUI.Message
// 使用Element UI
Vue.use(ElementUI);

// 注册全局常量
Vue.use(CONSTANT)
// 执行路由守卫
setupRouterGuard()

Vue.directive('myTitle', {
  inserted: function (el, binding) {
    const { value } = binding
    // 方式1，直接给v-myTitle标签的 data-title 属性赋值
    if (el.dataset.title) {
      document.title = el.dataset.title
    }
    // 方式2，通过指令传参（{{xxx}}）
    else if (value && value.title) {
      document.title = value.title
    }
  },
  update (el, binding) {
    const { value } = binding
    if (el.dataset.title) {
      document.title = el.dataset.title
    } else if (value && value.title) {
      document.title = value.title
    }
  }
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

