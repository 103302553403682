/**
 * 系统的vuex
 */
import {SET_STORAGE} from "@/utils";
import Vue from 'vue'

export const systemVuex = {
    // 开启命名空间
    namespaced: true,
    // 命名变量
    state: {
        accountInfo:{}, // 账号信息
        token: ''  // 单独获取token
    },
    // 修改/添加state里面的变量
    mutations: {
        SAVE_ACCOUNT_INFO(state, value = {}) {
            // 保存信息
            state.accountInfo = {...value}
            // 保存token
            state.token = state.accountInfo && state.accountInfo.token
            // 保存到浏览器缓存
            SET_STORAGE(Vue.$ACCOUNT_INFO, state.accountInfo)
            SET_STORAGE(Vue.$TOKEN, state.token)
        },
    },
    //获取
    getters: {
        GET_ACCOUNT_INFO(state) {
            return state.accountInfo
        }
    }
}