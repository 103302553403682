/**
 * 路由守卫
 */
import {GET_STORAGE, CLEAR_STORAGE} from "@/utils";
import Vue from 'vue'
// 管理员页面白名单
const pages = [
    '/bdfff',
    '/templateEntrance', '/templateEntrancePlus', '/403', '/sm02', '/qh01', '/shccc1', '/ymcc01', '/qhhda2', '/sh02', '/gshc6', '/yf62802', '/sjhy07',
    '/sjhy06', '/hfly7', '/jy01', '/sqt01',
    '/hongkongtest', '/blbc1', '/cjbc1', '/xzs24', '/xzs23',
    '/scyy7',
    '/yf70502',
    '/yf70501',
    '/yf70403',
    '/yf70402',
    '/yf70401',
    '/yjy14',
    '/yjy13',
    '/sb02',
    '/sb01',
    '/yf71102',
    '/yf71101',
    '/wc02',
    '/jxhm01',
    '/YT01',
    '/wc01',
    '/sle7',
    '/sl01',


]
const ipPages = [
    "localhost",
    "edfront.yjkjrj.com",
    "edfront.yjkjrj.cn",
    "192.168.1.121",

]

export const createPermissionGuard = router => {
    router.beforeEach((to, from, next) => {


        var ip = window.location.host.split(":");

        // 获取用户信息
        const accountInfo = GET_STORAGE(Vue.$ACCOUNT_INFO) || {}
        // 获取token
        const token = GET_STORAGE(Vue.$TOKEN) || ''

        //不用登录访问 并且不在白名单内 并且域名不是自己的
        if ((!ipPages.includes(ip[0]) && (!token || token === 'undefined')) && !pages.includes(to.path)) {
            next('/403')
            return
        }


        //不用登录访问 并且在白名单内
        if (pages.includes(to.path)) {
            // 白名单模板
            next()
            return;
        }


        // 直接去登录页，放行
        if (to.path === '/defaultLogin') {
            CLEAR_STORAGE()
            next()
            return
        }

        // 不是登录页并且没有登录，去登录
        if (!token || token === 'undefined') {
            next('/defaultLogin')
            return
        }
        // 不是去登录页，并且登录了，权限是管理员
        if (accountInfo.roleId === 0 || accountInfo.roleId === 1 || accountInfo.roleId  ===2) {
            next()
        } else {
            // 403 表示无权限进入的页面
            next('/403')
        }

    })
}
