<script src="main.js"></script>
<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {GET_STORAGE} from "@/utils";
import {mapMutations} from "vuex";
import {ACCOUNT_INFO} from '@/utils/constant'

export default {
  // 这是为了持久化vuex 的数据
  created() {
    // 1. 持久化数据信息
    if (GET_STORAGE(ACCOUNT_INFO)) {
      this.SAVE_ACCOUNT_INFO(GET_STORAGE(ACCOUNT_INFO))
    }
  },
  methods: {
    ...mapMutations("systemVuex", ['SAVE_ACCOUNT_INFO']),
  }
}
</script>

