import Vue from 'vue'
import Vuex from 'vuex'
import {systemVuex} from './System/system'
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        // 系统模块
        systemVuex
    },
})
