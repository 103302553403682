import axios from 'axios'
import Vue from 'vue'
import {systemVuex} from "@/store/System/system";
import {CLEAR_STORAGE} from "@/utils";

// 状态码
const ErrorCode = {
    OK: 0, // 操作成功
    PARAMS_ERROR: 40000, // 请求参数错误
    NO_AUTH: 40001, // 没有权限
    NOT_LOGIN: 40002, // 没有登录
    HAVE_ACCOUNT: 40003, // 注册已有账号
    NOT_ACCOUNT: 40004, // 登录账号不存在
    OPERATE_FAIL: 40005, // 操作失败
    REGISTER_FAIL: 40006, // 注册失败
    SYSTEM_FAIL: 50000 // 系统内部错误
}

// 设置超时时长
axios.defaults.timeout = 600000
// 基本路径
// axios.defaults.baseURL = "/api"
//国内
axios.defaults.baseURL = "http://edbackend.yjkjrj.com/api"

// request拦截器
axios.interceptors.request.use((config) => {
    if (systemVuex.state.token) {
        config.headers.token = `${systemVuex.state.token}`
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

// response拦截器
axios.interceptors.response.use((res) => {
    const data = res && res.data
    switch (data.code) {
        case ErrorCode.OK:
            return data;
        case ErrorCode.SYSTEM_FAIL:
            // 系统错误，需要单独提示
            Vue.$message.error("请求失败，联系管理员");
            break;
        case ErrorCode.NOT_LOGIN:
            // 没有登录
            Vue.$message.error("请求失败，原因：" + data.message + `${data.description ? "，" + data.description : ''}`);
            CLEAR_STORAGE()
            window.location.href = "/defaultLogin"
            break;
        case ErrorCode.PARAMS_ERROR:
            Vue.$message.error("请求失败，原因：" + data.message + `${data.description ? "，" + data.description : ''}`);
            break;
        case ErrorCode.NO_AUTH:
            Vue.$message.error("请求失败，原因：" + data.message + `${data.description ? "，" + data.description : ''}`);
            break;
        case ErrorCode.HAVE_ACCOUNT:
            Vue.$message.error("请求失败，原因：" + data.message + `${data.description ? "，" + data.description : ''}`);
            break;
        case ErrorCode.NOT_ACCOUNT:
            Vue.$message.error("请求失败，原因：" + data.message + `${data.description ? "，" + data.description : ''}`);
            break;
        case ErrorCode.OPERATE_FAIL:
            Vue.$message.error("请求失败，原因：" + data.message + `${data.description ? "，" + data.description : ''}`);
            break;
        case ErrorCode.REGISTER_FAIL:
            Vue.$message.error("请求失败，原因：" + data.message + `${data.description ? "，" + data.description : ''}`);
            break;
        default :
            Vue.$message.error(`请求失败，请重试!`);
            break;
    }
    return data
})

/**
 * 设置请求header
 * @param {Object} headers
 */
const setHeader = (headers) => {
    for (const key in headers) {
        axios.defaults.headers[key] = headers[key]
    }
}

/**
 * POST请求
 * @param {String} url 地址
 * @param {Object} params 参数
 * @param {Object} headers headers
 */
export const methodPost = (url, params, headers = {}) => {
    return new Promise((resolve, reject) => {
        setHeader(headers)
        axios.post(
            url, params).then((response) => {
            resolve(response.data)
        }).catch((error) => {
            reject(error)
        })
    })
}


/**
 * 文件上传
 * @param {String} url 地址
 * @param files
 */
export const methodUpload = (url, files) => {
    return new Promise((resolve, reject) => {
        const formData = new FormData()
        files.forEach(item => {
            formData.append("file", item)
        })
        axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            resolve(response.data)
        }).catch((error) => {
            reject(error)
        })
    })
}


/**
 * 文件下载
 * @param {String} url 地址
 * @param params 参数
 */
export const methodDownload = (url, params) => {
    return new Promise((resolve, reject) => {
        axios.request({
            url: url,
            method: 'GET',
            params: params,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', //设置请求头，根据后台需要格式
            }
        }).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error)
        })
    })
}
