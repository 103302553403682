/**
 * 全局常量
 */
import Vue from 'vue'

export const ACCOUNT_INFO = 'accountInfo' // 账号信息
export const TOKEN = 'token' //token
export const ADMIN = 0 // 超级管理员
export const STUDENT = 1 // 运维
export const ACTION = 'ACTION' // 操作咧

export const BASEURL = "http://localhost:9090/api"

export default {
    install() {
        Vue.$ACCOUNT_INFO = ACCOUNT_INFO
        Vue.$TOKEN = TOKEN
        Vue.$ADMIN = ADMIN
        Vue.$STUDENT = STUDENT
        Vue.$BASEURL = BASEURL
        Vue.$ACTION = ACTION
    }
}
