import {methodPost,methodUpload, methodDownload} from '@api/index'
/**
 * 下载文件，/common/download
 */
export const commonDownloadAPI = (params) => {
    return methodDownload("/common/download", params);
}

/**
 * 上传文件，/minio/upload
 */
export const commonUploadAPI = (params) => {
    // 返回
    return methodUpload("/minio/upload", params);
}


/**
 * 删除文件，/minio/delete
 */
export const commonDeleteAPI = (params) => {
    // 返回
    return methodPost("/minio/delete", params);
}

